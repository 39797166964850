/* eslint-disable @typescript-eslint/no-use-before-define */
import { fonts } from '@helpers/fonts';
import { ButtonReset } from '@helpers/global';
import { Device, from, until } from '@helpers/media';
import srOnly from '@helpers/srOnly';
import { ThemeSwitch } from '@helpers/theme';
import { ReactComponent as SvgSearch } from '@img/icons/search-button.svg';
import { ReactComponent as SvgSearchClose } from '@img/icons/search-close.svg';
import styled, { css } from 'styled-components';
const Container = styled.div `
  @media ${until(Device.DesktopSmall)} {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    align-items: center;
    background: #fff;
    height: 65px;
    padding: 0px 10px;
    width: 100%;
    /* High because the owl-carousel buttons have 8000... */
    z-index: 8001;

    ${({ active }) => active &&
    css `
        display: flex;
      `}
  }

  @media ${from(Device.DesktopSmall)} {
    position: relative;
    /* High because the owl-carousel buttons have 8000... */
    z-index: 8001;

    ${({ showResults }) => showResults &&
    css `
        &::after {
          content: '';
          position: fixed;
          top: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.4);
          height: 100%;
          width: 100%;
          z-index: 1;
        }
      `}
  }
`;
const Inner = styled.div `
  display: flex;
  width: 100%;

  @media ${from(Device.DesktopSmall)} {
    position: relative;
    z-index: 2;
  }
`;
const Input = styled.input `
  display: block;
  flex: 1 1 auto;
  background: #fff;
  border: 1px solid #b9b9b9;
  border-right: 0;
  border-radius: 0;
  color: #575757;
  ${fonts.DaxPro.Regular};
  font-size: 16px;
  height: 45px;
  line-height: 20px;
  outline: none;
  padding: 12px 14px;
  transition: border-color 0.15s ease-out;

  &:focus {
    border-color: #000;
    outline: none;
  }
`;
const Submit = styled.button.attrs({ type: 'button' }) `
  ${ButtonReset};
  display: flex;
  align-content: center;
  align-items: center;
  ${ThemeSwitch({ groundforceColour: '#6f9d20', groundforceColourHover: '#a2b952' })}
  background: var(--theme);
  color: #fff;
  flex: 0 0 45px;
  height: 45px;
  padding: 10px;
  justify-content: center;
  justify-items: center;
  transition: background-color 0.15s ease-out, color 0.15s ease-out;
  width: 45px;

  &:focus {
    outline: none;
  }

  &:focus,
  &:hover {
    color: #fff;
    background: var(--theme-hover);
  }
`;
const SubmitLabel = styled.span `
  ${srOnly}
`;
const SubmitIcon = styled(SvgSearch) `
  display: block;
  color: currentColor;
  height: 27px;
  width: 27px;
`;
const Close = styled.button.attrs({ type: 'button' }) `
  display: flex;
  align-content: center;
  align-items: center;
  background: none;
  color: #333333;
  flex: 0 0 45px;
  height: 45px;
  margin: 0 0 0 16px;
  padding: 0;
  justify-content: center;
  justify-items: center;
  width: 45px;

  &:focus {
    outline: none;
  }

  &:focus,
  &:hover {
    background: none;
    color: #333333;
  }

  @media ${from(Device.DesktopSmall)} {
    display: none;
  }
`;
const CloseIcon = styled(SvgSearchClose) `
  display: block;
  color: currentColor;
  height: 23px;
  width: 23px;
`;
const CloseLabel = styled.span `
  ${srOnly}
`;
const ResultsContainer = styled.div `
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  border: 1px solid #b9b9b9;
  max-height: 410px;
  width: 100%;
  z-index: 1;

  @media ${until(Device.DesktopSmall)} {
    height: calc(100vh - 65px);
    max-height: none;
  }
`;
const ResultItem = styled.a `
  position: relative;
  display: flex;
  align-items: center;
  color: #333333;
  height: 45px;
  ${fonts.DaxPro.Regular};
  font-size: 16px;
  line-height: 28px;
  overflow: hidden;
  padding: 0 10px;
  text-decoration: none;
  text-indent: 4px;
  white-space: nowrap;

  &:focus,
  &:hover {
    color: #333333;
    text-decoration: underline;

    ${() => ResultItemText} {
      transform: translate3d(10px, 0, 0.1px);
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    border-bottom: 1px solid #dcdcdc;
    margin: 0 10px;
    width: calc(100% - 20px);
  }

  &:last-child::after {
    content: none;
  }
`;
const ResultItemText = styled.span `
  overflow: hidden;
  text-overflow: ellipsis;
  transform: translate3d(0, 0, 0.1px);
  transition: transform ease-out 0.15s;

  em {
    font-style: normal;
    ${fonts.DaxPro.Bold};
  }
`;
export default {
    Close,
    CloseIcon,
    CloseLabel,
    Container,
    Inner,
    Input,
    ResultItem,
    ResultItemText,
    ResultsContainer,
    Submit,
    SubmitIcon,
    SubmitLabel,
};
