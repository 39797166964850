import useFocusWithin from '@hooks/useFocusWithin';
import * as React from 'react';
import { useLockBodyScroll } from 'react-use';
import S from './SearchField.styles';
const SearchField = ({ searchResultsUrl, showSearchMobile, placeholderText, searchText, closeText, visibleCount = 9, onClose, typeAheadPath, isTraining = false, }) => {
    const [searchTerm, setSearchTerm] = React.useState('');
    const items = useApi(searchTerm, typeAheadPath, isTraining);
    const refContainer = React.useRef(null);
    const refResultsContainer = React.useRef(null);
    const refInput = React.useRef(null);
    const showResults = useFocusWithin(refContainer);
    useLockBodyScroll(showResults && !!items.length);
    React.useEffect(() => {
        if (showSearchMobile) {
            refInput.current?.focus();
        }
    }, [showSearchMobile]);
    const getItemText = (item) => {
        // Get the word start
        const startIndex = item.value.toLowerCase().indexOf(searchTerm.toLowerCase());
        // Return original if we can't find it
        if (startIndex < 0) {
            return item.value;
        }
        // Get the phrase out of the word so it keeps its original casing
        const phrase = item.value.substring(startIndex, startIndex + searchTerm.length);
        // Replace the phrase in the original word and wrap it in a span so we can style it
        const replacedText = item.value.replace(new RegExp(phrase, 'g'), `<em>${phrase}</em>`);
        return React.createElement(S.ResultItemText, { dangerouslySetInnerHTML: { __html: replacedText } });
    };
    return (React.createElement(S.Container, { ref: refContainer, active: showSearchMobile, showResults: showResults && !!items.length, onKeyDown: (event) => {
            if (event.key.toLowerCase() === 'arrowdown') {
                handleArrowDown(event);
            }
            if (event.key.toLowerCase() === 'arrowup') {
                handleArrowUp(event);
            }
            if (event.key.toLowerCase() === 'escape') {
                handleEscape(event);
            }
            if (event.key.toLowerCase() === 'enter') {
                handleRedirect(event, isTraining);
            }
        } },
        React.createElement(S.Inner, null,
            React.createElement(S.Input, { ref: refInput, placeholder: placeholderText, type: "search", value: searchTerm, autoComplete: "off", onChange: (event) => {
                    setSearchTerm(event.target.value);
                } }),
            React.createElement(S.Submit, { onClick: (e) => handleRedirect(e, isTraining), "aria-label": "Search" },
                React.createElement(S.SubmitLabel, null, searchText),
                React.createElement(S.SubmitIcon, null)),
            onClose && (React.createElement(S.Close, { type: "button", onClick: (event) => {
                    event.preventDefault();
                    onClose();
                } },
                React.createElement(S.CloseLabel, null, closeText),
                React.createElement(S.CloseIcon, null))),
            showResults && !!items.length && (React.createElement(S.ResultsContainer, { ref: refResultsContainer }, items.slice(0, visibleCount).map((item) => (React.createElement(S.ResultItem, { key: item.url, href: item.url, tabIndex: -1 }, getItemText(item)))))))));
    function handleRedirect(event, isTraining) {
        event.preventDefault();
        if (!searchTerm || searchTerm.length === 0 || searchResultsUrl === null) {
            return;
        }
        window.location.href = `${searchResultsUrl}?searchtext=${searchTerm}${isTraining ? `&isTraining=${isTraining}` : ''}`;
    }
    function handleArrowDown(event) {
        event.preventDefault();
        if (showResults) {
            const resultSelected = refResultsContainer.current?.contains(document.activeElement);
            if (resultSelected) {
                const nextResult = document.activeElement?.nextElementSibling;
                if (nextResult) {
                    nextResult.focus();
                }
            }
            else {
                const firstResult = refResultsContainer.current?.firstChild;
                if (firstResult) {
                    firstResult.focus();
                }
            }
        }
    }
    function handleArrowUp(event) {
        event.preventDefault();
        if (showResults) {
            const resultSelected = refResultsContainer.current?.contains(document.activeElement);
            if (resultSelected) {
                const previousResult = document.activeElement?.previousElementSibling;
                if (previousResult) {
                    previousResult.focus();
                }
            }
        }
    }
    function handleEscape(event) {
        event.preventDefault();
        if (showResults) {
            const resultSelected = refResultsContainer.current?.contains(document.activeElement);
            if (resultSelected) {
                refInput.current?.focus();
            }
        }
    }
};
export default SearchField;
/** Use typeahead results for search term. */
function useApi(searchTerm, typeAheadPath, isTraining) {
    const [data, setData] = React.useState([]);
    React.useEffect(() => {
        if (!searchTerm || searchTerm.length < 3) {
            return setData([]);
        }
        // Check for AbortController support (ie11).
        const abortController = window.AbortController ? new AbortController() : undefined;
        fetchApi(searchTerm, abortController?.signal, typeAheadPath, isTraining).then((data) => setData(data));
        return function cancel() {
            abortController?.abort();
        };
    }, [searchTerm]);
    return data;
}
/** Call typeahead API endpoint. */
async function fetchApi(searchTerm, signal, typeAheadPath, isTraining) {
    const res = await fetch(`/api/typeahead/getsearchitems?query=${searchTerm}${typeAheadPath ? `&path=${typeAheadPath}` : ''}${isTraining ? `&isTraining=${isTraining}` : ''}`, { signal });
    if (!res.ok) {
        return [];
    }
    const data = await res.json();
    if (!data) {
        return [];
    }
    return data;
}
